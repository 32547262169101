import { Injectable } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import { TableField } from '@proman/interfaces/object-interfaces';
import { Invoice, InvoiceRecord, Order } from '@proman/interfaces/entity-interfaces';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { roundNumber, safeMap } from '@proman/utils';
import { Money } from '@proman/interfaces/common.interface';
import moment from 'moment';
import {invoice} from "@proman/resources/invoice";

export const INVOICE_DIALOG_WIDTH: string = '1200px';

@Injectable()
export class InvoicesService {

    constructor(
        private Filter: FilterService,
        private LocalStorage: LocalStorageService
    ) {}

    translateIsPaid = (invoice: Invoice) => {
        return this.Filter.translate(invoice.isPaid ? 'yes' : 'no');
    };

    translateIsProforma = (invoice: Invoice) => {
        return this.Filter.translate(invoice.type === 'proforma' ? 'proforma' : 'invoice');
    };

    translateIsOverdue = (invoice: Invoice) => {
        return this.Filter.translate(invoice.isOverdue ? 'yes' : 'no');
    };

    translateIsInvalidated = (invoice: Invoice) => {
        return this.Filter.translate(invoice.isInvalidated ? 'yes' : 'no');
    };

    getRegion = (invoice: Invoice) => {
        return invoice?.customer?.country?.regions? safeMap(invoice.customer.country.regions, 'name').join(', ') : '';
    };

    getDates = (row: Invoice) => {
        return row.payments.length > 0 ?
            row.payments.map((payment) => this.Filter.date(payment.date)).join(', ') :
            this.Filter.date(row.paidAt);
    }

    getProducts = (invoice: Invoice) => {
        return invoice.records.map((record) => record.name).join(', ');
    };

    localStorageValue = (key: string) => {
        return this.LocalStorage.getObject('uiPrefs.ss.entityTable')['invoices']['fields'].filter((e: any) => e.key === key).length > 0;
    }

    getFields = (): TableField[] => {
        return [
            {
                name: 'date',
                key: 'date',
                formatter: 'dateTime',
                showTime: false,
            },
            {
                name: 'payment_deadline',
                key: 'deadline',
                formatter: 'dateTime',
                hidden: true
            },
            {
                name: 'paid_at',
                key: 'paidAt',
                formatter: 'dateTime',
                hidden: true
            },
            {
                name: 'payments',
                key: 'payments',
                formatter: 'dateTime',
                getValue: this.getDates,
                hidden: true
            },
            {
                name: 'is_paid',
                key: 'isPaid',
                getValue: this.translateIsPaid,
                filter: {
                    type: 'dropdown',
                    options: [
                        {
                            id: true,
                            name: this.Filter.translate('yes')
                        },
                        {
                            id: false,
                            name: this.Filter.translate('no')
                        }
                    ],
                    key: 'id'
                },
                hidden: true
            },
            {
                name: 'invoice_number',
                key: 'number'
            },
            {
                name: 'id',
                key: 'id',
                hidden: true,
            },
            {
                name: 'customer_name',
                key: 'customer.name',
                hidden: true,
            },
            {
                name: 'customer_registration_code',
                key: 'customer.registrationCode',
                hidden: true,
            },
            {
                name: 'customer_vat_code',
                key: 'customer.vatCode',
                hidden: true,
            },
            {
                name: 'customer',
                key: 'customer.alias'
            },
            {
                name: 'region',
                key: 'customer.country.regions',
                getValue: this.getRegion,
                sort: false,
                filter: {
                    type: 'autocomplete',
                    entity: 'region'
                },
                extraJoins: [
                    'customer.country',
                    'customer.country.regions'
                ],
                hidden: true
            },
            {
                name: 'country',
                key: 'customer.country.name',
                extraJoins: ['customer.country'],
                hidden: true,
            },
            {
                name: 'order_ids',
                key: 'relatedOrders',
                getValue: (row: Invoice) => [...new Set(row.relatedOrders || [])].join(','),
                filter: {
                    type: 'search',
                    keys: ['records.order.name', 'records.order.id'],
                },
                hidden: true,
            },
            {
                name: 'order_names',
                key: 'records.order.name',
                getValue: (row: Invoice) => {
                    const orders = row.records.filter((record) => !!record.order).map((record) => record.order);
                    const orderMap: { [key: number]: Order } = orders.reduce((a, b) => {a[b.id] = b; return a; }, {});
                    return Object.keys(orderMap).map((id) => orderMap[id].name).join(', ');
                },
                filter: {
                    type: 'search',
                    keys: ['records.order.name'],
                },
                extraJoins: ['records', 'records.order'],
            },
            {
                name: 'customer_number',
                key: 'records.order.customerNumber',
                getValue: (row: Invoice) => {
                    const orders = row.records.filter((record) => !!record.order).map((record) => record.order);
                    const orderMap: { [key: number]: Order } = orders.reduce((a, b) => {a[b.id] = b; return a; }, {});
                    return Object.keys(orderMap).map((id) => orderMap[id].customerNumber).join(', ');
                },
                extraJoins: ['records', 'records.order'],
                hidden: true
            },
            {
                key: 'records.order.type.name',
                name: 'order_types',
                getValue: (row: Invoice) => {
                    const orders = row.records.filter((record) => !!record.order).map((record) => record.order);
                    const orderMap: { [key: number]: Order } = orders.reduce((a, b) => {a[b.id] = b; return a; }, {});
                    return Object.keys(orderMap).map((id) => orderMap[id].type?.name).join(', ');
                },
                extraJoins: ['records', 'records.order', 'records.order.type'],
                hidden: true,
            },
            {
                name: 'products',
                key: 'records.name',
                getValue: this.getProducts,
                extraJoins: ['records'],
                hidden: true,
            },
            {
                name: 'quantity',
                key: 'quantity',
                stats: ['sum'],
                formatter: 'numeric',
                hidden: true
            },
            {
                name: 'sum',
                key: 'sumLocalCurrency',
                stats: ['sum'],
                formatter: 'money',
            },
            {
                name: 'vat_sum',
                key: 'sumLocalCurrencyVat',
                formatter: 'money',
                stats: ['sum'],
            },
            {
                name: 'discount',
                key: 'discount',
                formatter: 'money',
                stats: ['sum'],
            },
            {
                name: 'transport_price',
                key: 'transportPrice',
                formatter: 'money',
                stats: ['sum'],
            },
            {
                name: 'sum_local_currency_with_vat',
                key: 'sumLocalCurrencyWithVat',
                stats: ['sum'],
                formatter: 'money',
            },
            {
                name: 'sum_in_foreign_currency',
                key: 'sumWithVat',
                stats: ['sum'],
                formatter: 'money',
            },
            {
                name: 'commission',
                key: 'order.commission',
                filter: null,
                getValue: this.getCommission,
                formatter: 'money',
                formatterConfig: 2,
                stats: ['sum'],
                extraJoins: ['records', 'records.order'],
            },
            {
                name: 'paid_amount',
                key: 'paidAmount',
                filter: null,
                stats: ['sum'],
                sort: false,
                getValue: (row: Invoice) => ({ amount: row.payments.reduce((i, a) => i + +a.sum.amount, 0), currency: row.sumWithVat.currency }),
                formatter: 'money',
            },
            {
                name: 'remnant',
                key: 'remnant',
                getValue: this.unpaidDifference,
                stats: ['sum'],
                formatter: 'money'
            },
            {
                name: 'proforma',
                key: 'type',
                getValue: this.translateIsProforma,
                filter: {
                    type: 'dropdown',
                    options: [
                        {
                            id: 'proforma',
                            name: this.Filter.translate('proforma')
                        },
                        {
                            id: 'invoice',
                            name: this.Filter.translate('invoice')
                        }
                    ],
                    key: 'id',
                    value: 'invoice',
                    userValueExpiry: 1000 * 60 * 60
                }
            },
            {
                name: 'is_invalidated',
                key: 'isInvalidated',
                getValue: this.translateIsInvalidated,
                filter: {
                    type: 'dropdown',
                    options: [
                        {
                            id: true,
                            name: this.Filter.translate('yes')
                        },
                        {
                            id: false,
                            name: this.Filter.translate('no')
                        }
                    ],
                    value: false,
                    userValueExpiry: 1000 * 60 * 60,
                    key: 'id'
                }
            },
            {
                name: 'is_overdue',
                key: 'isOverdue',
                getValue: this.translateIsOverdue,
                filter: {
                    type: 'dropdown',
                    options: [
                        {
                            id: true,
                            name: this.Filter.translate('yes')
                        },
                        {
                            id: false,
                            name: this.Filter.translate('no')
                        }
                    ],
                    key: 'id'
                },
                hidden: true
            },
            {
                name: 'created_by',
                key: 'createdBy.name',
            },
          {
            name: 'late_interest',
            key: 'lateInterest',
            filter: null,
            sort: null,
            formatter: 'money',
            stats: ['sum'],
            getValue: (row: Invoice) => {
              if (row.customer.lateInterestPercentage) {
                const deadline = moment(row.deadline);
                const today = moment();
                const amount = +row.sumWithVat.amount * (+row.customer.lateInterestPercentage / 100) * today.diff(deadline, 'days');
                return { currency: row.sumWithVat.currency, amount};
              } else {
                return { currency: row.sumWithVat.currency, amount: 0 };
              }
            }
          }
        ];
    };

    getCommission = (invoice: Invoice): Money => {
        let commission = 0;
        invoice.records?.forEach((record: InvoiceRecord) => {
            if (record.order) {
                const sum = +record.sum.amount * (1 - (record.order?.customerDiscountPercent ?? 0) / 100);
                commission += roundNumber(sum * (record.order?.agentCommissionPercent ?? 0) / 100);
            }
        })
        return {
            amount: commission.toString(),
            currency: invoice.sum.currency
        }
    }
    unpaidDifference = (invoice: Invoice) => {
        return {
            amount: +invoice.sumLocalCurrencyWithVat.amount - +this.paidSum(invoice).amount,
            currency: invoice.sumLocalCurrencyWithVat.currency
        }
    }

    paidSum = (invoice: Invoice) => {
        return Object.assign({}, {
            'amount': invoice.payments?.reduce((a, b) => a + (+b.sum.amount / +invoice.ratioOnCreate), 0) ?? 0,
            'currency': invoice.sumLocalCurrencyWithVat.currency
        })
    }
}
